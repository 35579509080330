const apiConfig = {
  getS3URL: "/dashboard/get-link-s3",
  insertFoto: "/dashboard/inserisci-foto",
  getFoto: "/dashboard/get-foto/",
  getPrenotazioni: "/dashboard/get-prenotazioni",
  getRooms: "/dashboard/get-rooms",
  inserisciPrenotazioni: "/dashboard/inserisci-prenotazioni",
  insertAppartamento: "/dashboard/inserisci-nuovo-appartamento",
  insertVendita: "/dashboard/inserisci-nuova-vendita",
  eliminaPrenotazione: "/dashboard/elimina-prenotazione",
  getPrenotazioneByID: "/dashboard/get-prenotazione/",
  modificaPrenotazione: "/dashboard/modifica-prenotazione",
  getAppartamento: "/dashboard/get-appartamento",
  inviaMailRiepilogo: "/dashboard/invia-mail-prenotazione",
  getSconti: "/dashboard/get-scontistica",
  inserisciPeriodoScontistica: "/dashboard/inserisci-periodo-scontistica",
  modificaAppartamento: "/dashboard/modifica-appartamento",
  modificaPeriodoScontistica: "/dashboard/modifica-periodo-scontistica",
  inserisciAssociazioneGiornoPeriodo: "/dashboard/inserisci-associazione-giorno-periodo",
  eliminaPeriodoScontistica: "/dashboard/elimina-periodo-scontistica",

}

export default apiConfig;  